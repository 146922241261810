<template>
  <div class="ml-5 mr-5">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>
    <div class="text-left">
      <v-container fluid>
        <v-row>
          <v-col lg="3" md="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Search by Month & Year"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                type="month"
                no-title
                scrollable
                :max="max"
                :min="min"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col lg="2" md="2">
            <v-text-field
              v-model="panelist_ids"
              label="Enter Panelist Ids Comma Seperated"
              clearable
              @keyup.enter="panelist_ids ? getReport(page-1) : ''"
            ></v-text-field>
          </v-col>

          <v-col lg="2" md="2">
            <v-btn small color="primary" class="mt-5" @click="getReport(page-1)">
              filter
            </v-btn>
            <v-btn small color="grey" class="mt-5" @click="resetSearch()">
              Reset
            </v-btn>
          </v-col>
          <v-col lg="3" md="3"></v-col>
          <v-col lg="2" md="2" class="text-right">
            <v-btn small color="primary" class="mt-5" @click="$router.go(-1)">
              Consumer Score Report
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4" md="4">
            <v-btn
              v-show="checkmodexist()"
              small
              color="primary"
              class="mt-5"
              @click="$router.push('/consumer-non-participating-report')"
            >
              Non Participating Panelist Report
            </v-btn>
          </v-col>
          <v-col lg="4" md="4"></v-col>
          <v-col lg="4" md="4" class="text-right">
            <v-btn small color="primary" class="mt-5" @click="exportReport()"
            v-show="checkIfOperationExistForModule('export')">
              export
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="tableWrapper">
              <v-data-table
                :headers="headers"
                item-key="competi_id"
                :page.sync="page"
                :items="affinity_data"
                :options.sync="options"
                :sortBy.sync="sortby"
                :sort-desc.sync="sortDesc"
                :server-items-length="totalAffinity"
                :loading="isLoading"
                class="elevation-1"
                hide-default-footer
                @page-count="pageCount = $event"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon dense class="eyebtn" @click="makeExtendedData(item)">
                    mdi-eye
                  </v-icon>
                </template>
                <template v-slot:top>
                  <v-dialog v-model="extendedDialogue" persistent width="auto">
                    <ExtendedData
                      :extended_data="extended_data"
                      :headers="extended_headers"
                      :title="title"
                      @clsdlg="clsdlg"
                    ></ExtendedData>
                  </v-dialog>
                </template>
              </v-data-table>
              <div class="text-center pt-4 pb-4">
                <Pagination
                  :isloading="isLoading"
                  :startRecord="startRecord"
                  :currentPage="currentPage"
                  :lastPage="lastPage"
                  :lastRecord="lastRecord"
                  :totRecords="totRecords"
                  :isCurrentPageClass="isCurrentPageClass"
                  :perpage="perpage"
                  :getLastPageClass="getLastPageClass"
                  :totPage="totPage"
                  :getPages="getPages"
                  @handlePerPage="handlePerPage"
                  @paginate="paginate"
                  @last="last"
                  @getDataByPage="getDataByPage"
                  :showPerPage="showPerPage"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- v-data-table-ends -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
import ExtendedData from "./component/ExtendedData.vue";
import Pagination from "./component/Pagination.vue";
export default {
  components: { Loading, ExtendedData, Pagination },
  data() {
    return {
      extended_headers: [
        {
          text: "Panelist ID",
          value: "competi_id",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Date",
          value: "date",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "DM Pieces",
          value: "direct_mail_piece",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "DM Points",
          value: "direct_mail_point",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Email Pieces",
          value: "email_piece",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Email Points",
          value: "email_piece_point",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Digital Points",
          value: "digital_point",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      title: "Consumer Score Monthly",
      date: new Date().toISOString().substr(0, 7),
      min: moment(new Date()).subtract(10, "years").format("YYYY-MM-DD"),
      max: moment(new Date()).format("YYYY-MM-DD"),
      menu: false,
      isLoading: true,
      reset_flag: false,
      sortby : "competi_id",
      sortDesc : false,
      fullPage: true,
      extended_data: [],
      headers: [
        {
          text: "Panelist ID",
          value: "competi_id",
          sortable: true,
          width: "120",
          class: "v-data-table-header",
        },

        {
          text: "Date",
          value: "date",
          sortable: true,
          width: "103",
          class: "v-data-table-header",
        },
        {
          text: "First Name",
          value: "first_name",
          sortable: true,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "Last Name",
          value: "last_name",
          sortable: true,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "Address",
          value: "address",
          sortable: true,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "City",
          value: "city",
          sortable: true,
          width: "103",
          class: "v-data-table-header",
        },
        {
          text: "State",
          value: "state",
          sortable: true,
          width: "103",
          class: "v-data-table-header",
        },
        {
          text: "Zip",
          value: "postalcode",
          sortable: true,
          width: "74",
          class: "v-data-table-header",
        },
        {
          text: "Email",
          value: "email",
          sortable: true,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "Bucket",
          value: "bucket",
          sortable: true,
          width: "103",
          class: "v-data-table-header",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "74",
          class: "v-data-table-header",
        },
      ],
      panelist_ids: "",
      page: 1,
      affinity_data: [],
      options: {},
      totalAffinity: 0,
      perpage: 50,
      totRecords: null,
      showPerPage: false,
      exportData: [],
      panelists_data: [],
      extendedDialogue: false,
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    endIndex: function () {
      if (this.page == 1) {
        return this.perpage;
      } else {
        return this.perpage * this.page;
      }
    },
    startIndex: function () {
      if (this.page == 1) {
        return this.perpage * 0;
      } else {
        return this.endIndex - this.perpage;
      }
    },
    fromDate: function () {
      let date = this.date;
      let dateArr = date.split("-");
      let month = dateArr[1];
      let year = dateArr[0];
      return year + "-" + month + "-01";
    },
    panelist_ids_arr: function () {
      if (this.panelist_ids == "" || this.panelist_ids == null) {
        return [];
      } else {
        let arr = this.panelist_ids.split(",");
        let new_arr = [];
        arr.forEach((id) => {
          new_arr.push(id.trim());
        });
        return new_arr;
      }
    },
    csv_name: function () {
      return "producer_scoring_monthly_report_.csv";
    },
    ///////
  },
  methods: {
    getReport(page = "", query = "", categ = "") {
      this.affinity_data = []
      let check = this;
      let crrpage = this.page - 1;
      let monthly_report_url = check.$url('admin_report_url') + "get_monthly_report";
      // let monthly_report_url = "http://10.0.0.206:5409/get_monthly_report";
      check.isLoading = true;
      if (page != "") {
        crrpage = page - 1;
      }
      let data = {
        competi_id: this.panelist_ids_arr,
        fromDate: this.date,
        sortby : this.sortby,
        sortdesc : this.sortDesc,
        page: crrpage,
        per_page: this.perpage,
      };
      console.log(data);
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .post(monthly_report_url, data)
        .then((res) => {
          check.isLoading = false;
          if (res.data.report.length) {
            let result_data = [];
            res.data.report.forEach(function (item) {
              result_data.push(item);
            });
            // res.data.report.forEach(function (item) {
            //   for (const key in item) {
            //       if (item.hasOwnProperty(key)) {
            //         if(extended_keys.includes(key)){
            //           extented_data

            //         }
            //       }
            //     }
            //   extented_data.push(item);
            // });
            this.affinity_data = result_data;
            this.totalAffinity = res.data.report_length;
            this.totRecords = res.data.report_length;

            if (this.totRecords <= this.perpage) {
              this.page = 1;
            }
          } else {
            this.affinity_data = res.data.report;
            this.totalAffinity = res.data.report_length;
            this.totRecords = res.data.report_length;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    exportReport() {
      let check = this;
      const exppath = check.$url('admin_report_url') + "export_monthly_data";
      check.isLoading = true;
      let data = {
        competi_id: this.panelist_ids_arr,
        fromDate: this.date,
        sortby:this.sortby,
        sortdesc : this.sortDesc,
      };
      this.$fetch({ requiresAuth: true, operation: "export", vueScope: check })
        .post(exppath, data)
        .then((res, status, xhr) => {
          check.isLoading = false;
          const anchor = document.createElement("a");
          anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(res.data);
          anchor.target = "_blank";
          let date = new Date().toISOString().split("T");
          date[1] = date[1].substring(0, 8);
          let strdate = date.join(" ");
          anchor.download = `consumer_scoring_monthly_report ${strdate}.csv`;
          anchor.click();
          //   URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    resetSearch() {
      if (
        this.panelist_ids != "" ||
        this.panelist_ids != null) {
        (this.page = 1),
          (this.panelist_ids = ""),
          (this.date = new Date().toISOString().substr(0, 7)),
          (this.sortby = "competi_id"),
          (this.sortDesc = false),
          (this.reset_flag=true),
          (this.perpage = 50);
        this.getReport();
      }
    },
    
    makeExtendedData(item) {
      this.extended_data = [];
      console.warn(item);
      let extended_keys = [
        "competi_id",
        "date",
        "direct_mail_piece",
        "direct_mail_point",
        "email_piece",
        "email_piece_point",
        "digital_point",
      ];
      let tempobj = {};
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          if (extended_keys.includes(key)) {
            tempobj[key] = item[key];
          }
        }
      }
      this.extended_data.push(tempobj);
      this.extendedDialogue = true
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getReport(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getReport(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getReport(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getReport(this.page);
    },
    clsdlg() {
      this.extendedDialogue = false;
    },
    checkmodexist : function(){
      return this.$utils.checkIfModuleExist(this,'Consumer Non Part Monthly Report')
    },
  },
  watch: {
    options: function(o){
      let sortby = o.sortBy[0]
      let direction = o.sortDesc[0]
      if(!this.reset_flag){
      if(sortby != undefined && direction != undefined ){
      this.sortby = sortby
      this.sortDesc = direction
      this.getReport();

      }
    }
    else{
    this.reset_flag = false
    }
    }
  },
  created() {
    this.getReport();
  },
};
</script>

<style lang="scss" scoped></style>
